import Vue from 'vue';
import Router from 'vue-router';
import Home from '../pages/Home.vue';
import NotFound from '../pages/NotFound.vue';
import SignIn from '../pages/SignIn.vue';
import RequestSend from '../pages/RequestSend.vue';
import Mnemo from '../pages/Mnemo.vue';
import Register from "../pages/Register.vue";

// import Myshow from "../pages/Myshow.vue";
// import Select from "../pages/Selectobject.vue";
import Paramdescription from "../pages/admin/Paramdescription.vue";
import CreateDevices from "../pages/admin/CreateDevices.vue";
import EditDevices from "../pages/admin/EditDevices.vue";
// import RegisterUser from "../pages/admin/users/RegisterUser.vue";
import AdminUserDevice from "../pages/admin/users/AdminUserDevice";


Vue.use(Router);

const router = new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      redirect: '/home',
    },
    {
      path: '/home/:iddqd?',
      name: 'Home',
      component: Home,
      meta: { nonRequiresAuth: true },
    },
    {
      path: '/requestsend',
      name: 'RequestSend',
      component: RequestSend,
    },
    // {
    //   path: '/select',
    //   name: 'Select',
    //   component: Select,
    // },
    {
      path: '/register',
      name: 'Register',
      component: Register,
      meta: { nonRequiresAuth: true },
      props: route => ({ confirmcode: route.query.confirmcode })
    },
    {
      path: '/mnemo/:iddqd?',
      name: 'Mnemo',
      component: Mnemo,
    },
    {
      path: '/paramdescription',
      name: 'Paramdescription',
      component: Paramdescription,
    },
    {
      path: '/createDevices',
      name: 'CreateDevices',
      component: CreateDevices,
    },
    {
      path: '/editDevices',
      name: 'EditDevices',
      component: EditDevices,
    },
    // {
    //   path: '/registeruser',
    //   name: 'RegisterUser',
    //   component: RegisterUser,
    // },
    {
      path: '/adminuserdevice',
      name: 'AdminUserDevice',
      component: AdminUserDevice,
    },
    {
      path: '/signIn',
      name: 'signIn',
      component: SignIn,
      meta: { loginPage: true, nonRequiresAuth: true },
    },
    {
      path: '*',
      component: NotFound,
    },
  ],
});

// router.beforeEach((to, from, next) => {
//   const requiresAuth = !to.matched.some((record) => record.meta.nonRequiresAuth);
//   const isLoginPage = to.matched.some((record) => record.meta.loginPage);
//   const isAuthenticated = localStorage.getItem('auth');
//   if (requiresAuth && !isAuthenticated) {
//     next('/signIn');
//   } else if (isLoginPage && isAuthenticated) {
//     router.push('/home');
//   }
//   next();  
// });

export default router;
