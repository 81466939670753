<template>
  <div>
    <h1>Для просмотра содержимого, вы должны авторизоваться</h1>
              <v-btn
            color="teal lighten-1"
            @click="downloadArchive"
            icon
            large
          >
            <v-icon>mdi-chart-areaspline</v-icon>
          </v-btn>

  </div>
</template>

<script>
// import SetParam from '../components/setParam.vue'
// import router from '../router';
// import MnemoService from '../services/mnemo.service'
// import MnemoService from '../services/mnemo.service'

export default {
  name: 'Home',
}

//   methods: {
//     SaveFile(filedata,filename){
//         var fileURL = window.URL.createObjectURL(new Blob([filedata]));
//         var fileLink = document.createElement('a');

//         fileLink.href = fileURL;
//         fileLink.setAttribute('download', filename);
//         // fileLink.setAttribute('content-disposition', "attachment; filename=archiveParams_1.zip")

//         // fileLink.headers = mnemoType.headers
//         // console.log("new headers")
//         // console.log(fileLink.headers)
//         document.body.appendChild(fileLink);

//      fileLink.click();
//     },
//     async downloadArchive(){
      
//       MnemoService.getArchiveParams1().then((response)=>{
//            // Log somewhat to show that the browser actually exposes the custom HTTP header

//           console.log(response)

//           const fileNameHeader = "x-suggested-filename";
//           const suggestedFileName = response.headers[fileNameHeader];
//           const effectiveFileName = (suggestedFileName === undefined
//                 ? "allergierOchPreferenser.xls"
//                 : suggestedFileName);
//           const effectiveFileNameRus = decodeURIComponent(effectiveFileName)      
//           console.log("Received header [" + fileNameHeader + "]: " + suggestedFileName
//                 + ", effective fileName: " + effectiveFileName);
//                 // Let the user save the file.
//           this.SaveFile(response.data, effectiveFileNameRus);
//       }).catch((response)=>{
//         console.error("Could not Download the Excel report from the backend.", response);
//       })
//     }
//   }
// }


//     //   try {
//     //     let mnemoType = MnemoService.getArchiveParams1()
//     //     mnemoType = await mnemoType;
//     //     console.log("orig headers")
//     //     console.log(mnemoType)

//     //     var fileURL = window.URL.createObjectURL(new Blob([mnemoType.data]));
//     //     var fileLink = document.createElement('a');

//     //     fileLink.href = fileURL;
//     //     // fileLink.setAttribute('download', mnemoType.headers);
//     //     fileLink.setAttribute('content-disposition', "attachment; filename=archiveParams_1.zip")

//     //     // fileLink.headers = mnemoType.headers
//     //     console.log("new headers")
//     //     console.log(fileLink.headers)
//     //     document.body.appendChild(fileLink);

//     //  fileLink.click();


//     //     // console.log(mnemoType.data)
//     //   }catch(error){
//     //     console.log("ошибка скачивания")
//     //   }
//     },
//   },
// }

</script>

<style >
  a {
    color: #42b983;
  }
</style>
