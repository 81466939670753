import axios from 'axios';
import authHeader from './auth-header';

const API_URL = '/api/order';
// const API_URL = 'http://localhost:9000/api/test/';

class MaillService {
  postSendOrder(data) {
    // console.log(JSON.stringify(data))
    // console.log('api',API_URL)
    return axios.post(API_URL , data, { headers: authHeader() });
  }
  // mySend(data) {
  //   console.log(JSON.stringify(data))
  //   console.log('api',API_URL)
  //   // return "dfdfdf"
  //   return axios.post(API_URL , data, { headers: authHeader() });
  // }

}

export default new MaillService();
