<template>
  <v-container class="teal lighten-1" fluid fill-height>
    <v-card class="mt-4 mx-auto text-center hidden-sm-only" min-width="320">
      <v-form @submit.prevent="handleActivate">
        <v-card-text>
          <v-text-field 
            label="Фамилия, инициалы *" 
            v-model="user.username" 
            :rules="[usernameRules.required]" 
            type="text">
          </v-text-field>
          <v-text-field 
            label="E-mail*" 
            v-model="user.email" 
            type="text"
            readonly
          >
          </v-text-field>
          <v-text-field 
            v-model="password" 
            label="Пароль*" 
            name="password" 
            :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'" 
            :rules="[passwordRules.required, passwordRules.minLenght]" 
            :type="showPassword ? 'text' : 'password'" 
            @click:append="showPassword = !showPassword">
          </v-text-field>
          <v-text-field 
            v-model="rePassword" 
            label="Подтверждение пароля*" 
            name="rePassword" 
            :append-icon="showRePassword ? 'mdi-eye' : 'mdi-eye-off'" 
            :rules="[passwordRules.required, passwordRules.minLenght, passwordConfirmationRule]" 
            :type="showRePassword ? 'text' : 'password'" @click:append="showRePassword = !showRePassword">
          </v-text-field>
          <div class="caption grey--text text--darken-1 text-left">* Обязательные поля</div>
        </v-card-text>
        <v-card-actions>
          <v-row align="center" no-gutters>
            <v-col class="text-center">
              <div class="my-2">
                <v-btn
                  type="submit"
                  dark
                  color="teal lighten-1"
                  autofocus
                >
                  Создать
                </v-btn>
              </div>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-container>
</template>

<script>
import User from '../models/newuser';
import AuthService from '../services/auth.service'

export default {
  name: 'Activate',
  props: ["confirmcode"],
  data() {
    return {
      user: new User('', '', '', ''),
      password: "",
      rePassword: "",
      showPassword: false,
      showRePassword: false,
      usernameRules: {
        required: (value) => !!value || "Пустое",
        minLength: (value) =>
          value.length >= 8 || "Должен содержать не менее 8 символов"
      },
      passwordRules: {
        required: (value) => !!value || "Пустое",
        minLenght: (value) =>
          value.length >= 8 || "Должен содержать не менее 8 символов"
      },
      emailRules: {
        required: (value) => !!value || "Пустое",
        valid: (value) => /.+@.+\..+/.test(value) || "Ошибка"
      }
    };
  },

  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
    error() {
      return this.$store.getters.getError;
    },
    passwordConfirmationRule() {
      return (
        this.password === this.rePassword || "Пароли должны совпадать"
      );
    }
  },
  watch: {
    error(value) {
      if (value) {
        this.alert = true;
      }
    },
    alert(value) {
      if (!value) {
        this.$store.dispatch('setError', false);
      }
    },
  },
  created() {
    // if (this.loggedIn) {
    //   this.$router.push('/profile');
    // }
    //  console.log('confirmcode',this.confirmcode)
    if(this.confirmcode){
      this.allowed=true
      this.getEmailOnUUID(this.confirmcode)

      // console.log('confirmcode есть: ', this.confirmcode)
    }else{
      // this.allowed=false
      console.log('confirmcode пустой: ')
    }
  },
  mounted(){
  },
  methods: {
    async getEmailOnUUID(uuid){
      console.log('getEmailOnUUID начало:',uuid)
      try {
        let authEmail = AuthService.emailOnUUID(uuid)
        // console.log('ждем ответа, запрос послан:',deviceID)
        authEmail = await authEmail
        this.user.email=authEmail.data
        this.user.uuid = uuid
        console.log('дождались ответа authEmail:',this.user.email)
        // this.authEmail = mnemoType.data.email
        // console.log('ответ:',this.mnemoTypeis)

      } catch (error) {
        console.log("Ошибочка вышла")
      }
    },
    async handleActivate() {
      console.log("handleRegister")
      // this.loading = true
      this.user.password = this.password
      console.log('user := ',JSON.stringify(this.user))

      // this.$validator.validateAll().then(isValid => {
      //   if (!isValid) {
      //     this.loading = false;
      //     return;
      //   }
      // })

      try {
        let activate = AuthService.activate(this.user)
        activate = await activate
        this.message = activate.message
        this.successful = true;
        this.$router.push('/');
      } catch (error) {
        console.log("Ошибочка вышла")
          this.message =
            (error.response && error.response.data) ||
            error.message ||
            error.toString();
          this.successful = false;
      }
    }
  }
};
</script>
