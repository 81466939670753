<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" persistent max-width="500">
      <template v-slot:activator="{ on, attrs }">
        <v-img
          src="../img/button1.png"
          class="img-button"
          v-bind="attrs"
          v-on="on"
          max-width="35"
          max-height="25"
        ></v-img>
      </template>
      <v-card>
        <v-card-title class="headline">
          Задайте новое значение параметра
          <span>
            <i>
              <b>"{{title}}"</b>
            </i>
          </span>
        </v-card-title>
        <v-card-text
          v-show="false"
        >Let Google help apps determine location. This means sending anonymous location data to Google, even when no apps are running.</v-card-text>
        <v-col cols="12" sm="6" md="6">
          <v-text-field v-model="value" :label="title" :placeholder="param.value.toString()" filled></v-text-field>
        </v-col>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="dialog = false">Отмена</v-btn>
          <v-btn color="green darken-1" text @click="setNewValue">Установить</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import MnemoService from "../services/mnemo.service";

export default {
  name: "setParam",
  props: ["title", "param"],
  data() {
    return {
      dialog: false,
      value: "",
    };
  },
  methods: {
    setNewValue() {
      // console.log('Нажата кнопка')
      //  console.log(this.value)
      this.dialog = false;
      const data = {
        paramId: this.param.paramid,
        value: this.value,
      };
      console.log("Нажата кнопка");
      console.log(data);
      console.log("отжата кнопка");
      MnemoService.setNewValue(data)
        .then((response) => {
          console.log("Get response: ", response.data);
          // this.param = this.parseResponse1(response);
        })
        .catch((error) => {
          this.alert = true;
          this.param = this.parseResponse1(error);
        });

      //             this.$axios
      //               .post('/api/parameters/updateparam',data)
      // //              .post('http://localhost:9000/api/parameters/updateparam',data)
      //               .then((response) => {
      //                 console.log('Get response: ', response.data);
      //                 // this.param = this.parseResponse1(response);
      //               })
      //               .catch((error) => {
      //                 this.alert = true;
      //                 this.param = this.parseResponse1(error);
      //               });
    },
  },
};
</script>

<style>
.img-button:hover {
  cursor: pointer;
}
</style>
