<template>
  <v-container>
    <v-data-table :headers="headers" :items="table" sort-by="calories" class="elevation-1" dense disable-pagination hide-default-footer>
      <template v-slot:top>
        <v-toolbar flat color="white">
          <v-toolbar-title>Таблица описаний параметров</v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-spacer></v-spacer>
          <v-dialog v-model="dialog" max-width="500px">
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="primary" dark class="mb-2" v-bind="attrs" v-on="on">New Item</v-btn>
            </template>
            <v-card>
              <v-card-title>
                <span class="headline">{{ formTitle }}</span>
              </v-card-title>

              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col cols="12" sm="6" md="4">
                      <v-text-field v-model="editedItem.id" label="ID параметра"></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="4">
                      <v-text-field v-model="editedItem.description" label="Описание параметра"></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="4">
                      <v-text-field v-model="editedItem.owenCode" label="Код owen параметра"></v-text-field>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="close">Cancel</v-btn>
                <v-btn color="blue darken-1" text @click="save">Save</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>
      <template v-slot:item.actions="{ item }" >
        <v-icon small class="mr-2" @click="editItem(item)">mdi-pencil</v-icon>
        <v-icon small @click="deleteItem(item)">mdi-delete</v-icon>
      </template>
      <template v-slot:no-data>
        <v-btn color="primary" @click="initialize">Reset</v-btn>
      </template>
    </v-data-table>
  </v-container>
</template>



<script>
//  name: "Paramdescription",
import AdminService from "../../services/admin.service";

export default {
  data() {
    return {
      dialog: false,
      headers: [
        {
          text: "id",
          align: "start",
          sortable: true,
          value: "id",
        },
        { text: "Описание", value: "description" },
        { text: "Код Owen", value: "owenCode" },
        { text: 'Actions', value: 'actions', sortable: false },
      ],
      // desserts: [],
      table: [],
      editedIndex: -1,
      editedItem: {
        id: 0,
        description: "",
        owenCode: "",
      },
      defaultItem: {
        id: 0,
        description: "",
        owenCode: "",
      },
    };
  },

  /////////////////data

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "New Item" : "Edit Item";
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
  },

  created() {
    this.initialize();
  },

  methods: {
    initialize() {
      this.myTableInit()
    },
    async myTableInit() {
      try {
        let paramdescription = AdminService.getParamdescription();
        paramdescription = await paramdescription;

        this.table = paramdescription.data
        // console.log('table', this.table)
      } catch (error) {
        this.alert = true;
        console.log("ALARM!!!!!!!!!");
        throw error;
      }
    },

    editItem(item) {
      this.editedIndex = this.table.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    deleteItem(item) {
      
      const index = this.desserts.indexOf(item);
      confirm("Are you sure you want to delete this item?") &&
        this.desserts.splice(index, 1) &&
        AdminService.delParamdescription(item)
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    async save() {
      if (this.editedIndex > -1) {
        let paramdescription = AdminService.postParamdescription(this.editedItem);
        paramdescription = await paramdescription;
        console.log("step1",this.table)
        console.log("step2",paramdescription.data)
        Object.assign(this.table[this.editedIndex], paramdescription.data);
        console.log("step3",this.table)
      } else {
        let paramdescription = AdminService.postParamdescription(this.editedItem);
        paramdescription = await paramdescription;

        this.table.push(paramdescription.data);
      }
      this.close();
    },
  },
};
</script>

<style scoped>
a {
  color: #42b983;
}
</style>
