import axios from 'axios';
import authHeader from './auth-header';

const API_URL = '/api/parameters/';

class MnemoService {
  // getPublicContent() {
  //   return axios.get(API_URL + 'categogies');
  // }

  getCategogies() {
    // console.log('categories')
    return axios.get(API_URL + 'categories', { headers: authHeader() })
  }

  getDevices() {
    return axios.get(API_URL + 'devices', { headers: authHeader() })
  }

  getParametersFromDevice(deviceID) {
    return axios.get(API_URL + deviceID, { headers: authHeader() })
  }

  setNewValue(data) {
    return axios.post(API_URL + 'updateparam', data, { headers: authHeader() })
  }

  getMnemoType(deviceID) {
    return axios.get(API_URL + 'device/' + deviceID, { headers: authHeader() })
  }

  getArchiveParams(deviceID) {
    return axios.get(API_URL + 'download/' + deviceID, { headers: authHeader() , responseType: 'blob'})
  }

  // getArchiveParams1() {
  //   // return axios.get(API_URL + 'excel/' + deviceID, { headers: authHeader() })
  //   return axios.get(API_URL + 'excel1/download' ,{responseType: 'blob'})
  // }
}

export default new MnemoService();



// convertCurrency('USD', 'HRK', 20)
//   .then((message) => {
//     console.log(message);
//   }).catch((error) => {
//     console.log(error.message);
//   });


//   const getCountries = async (currencyCode) => {
//     try {
//       const response = await axios.get(`https://restcountries.eu/rest/v2/currency/${currencyCode}`);
//       return response.data.map(country => country.name);
//     } catch (error) {
//       throw new Error(`Unable to get countries that use ${currencyCode}`);
//     }

//   };

//   const convert = async (fromCurrency, toCurrency, amount) => {
//     const exchangeRate = await getExchangeRate (fromCurrency, toCurrency);
//     const countries = await getCountries(toCurrency);
//     const convertedAmount = (amount * exchangeRate).toFixed(2);

//     return '${amount} ${fromCurrency} is worth ${convertedAmount} ${toCurrency}. You can spend these in the following countries: ${countries}';
//   }
