<template>
  <v-container>
    <v-row>
      <v-col>
        <h3>Добавить нового пользователя</h3>
        <v-alert
          v-model="alert"
          type="error"
          dismissible
        >
          {{ error }}
        </v-alert>
      </v-col>
    </v-row>
    <form @submit.prevent="handleAddNewUser">
      <v-row>
        <v-col>
          <v-text-field
            id="username"
            v-model="user.email"
            name="email"
            label="E-mail"
            type="email"
            required
          />
        </v-col>
        <v-col>
          <v-btn
            type="submit"
            dark
            color="teal lighten-1"
            autofocus
          >
            Добавить
          </v-btn>
        </v-col>
      </v-row>
    </form>
    <v-row>
      <v-col class=".col-md-4">
        <v-list-item-group
        v-model="seiId"
        color="primary"
      >
        <v-list-item dense
            v-for="user in users"
            :key="user.id"
        >
          <v-list-item-content>
            <v-list-item-title v-text="user.email"></v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        </v-list-item-group>
      </v-col>
      <v-col>
        <v-data-table
            :headers="headers"
            :items="deviceTable"
            item-key="name"
            class="elevation-1"
            v-model="selected"
            show-select
            dense 
            disable-pagination 
            hide-default-footer
        >
          <template v-slot:top>
            <h3>Действие с выделенными:</h3>
            <v-btn @click="updateUserDevice" > Применить</v-btn>
          </template>
        </v-data-table>

      </v-col>
    </v-row>

  </v-container>
</template>

<script>
import MnemoService from '../../../services/mnemo.service'
import AdminService from '../../../services/admin.service'
import AuthService from '../../../services/auth.service';
import User from '../../../models/user'

export default {
  name: 'AdminUserDevice',
  data() {
    return {
      user: new User('', ''),
      message: '',
      alert: false,
      error: null,
      selected: [],
      seiId:0,
      headers: [
        {
          text: 'id',
          align: 'start',
          sortable: true,
          value: 'idd',
        },
        { text: 'Название', value: 'name' }
      ],
      deviceTable: [],
      // deviceTable :[
      //   {idd:1,name:'цех1',categories:3},
      //   {idd:2,name:'цех2',categories:3},
      //   {idd:3,name:'цех3',categories:3},
      //   {idd:4,name:'цех4',categories:3},
      //   {idd:5,name:'цех5',categories:3},
      //   {idd:6,name:'цех6',categories:3},
      //   ],
      // users :[
      //   {email:'kopendakov@gmail.com',devices:[{id:1,name:'цех1'},{id:2,name:'цех2'},{id:3,name:'цех3'}]},
      //   {email:'alex_kag@mail.ru',devices:[{id:2,name:'цех2'},{id:3,name:'цех3'},{id:5,name:'цех5'}]},
      //   {email:'alex_wiz@mail.ru',devices:[{id:1,name:'цех1'},{id:3,name:'цех3'},{id:6,name:'цех6'}]},
      // ],
      users:[],
    };
  },
  computed: {
    // loggedIn() {
    //   return this.$store.state.auth.status.loggedIn;
    // },
    // error() {
    //   return this.$store.getters.getError;
    // },
  },
  watch: {
    seiId(value){
      this.selected=this.users[value].devices
      // console.log(value)

    }
    // error(value) {
    //   if (value) {
    //     this.alert = true;
    //   }
    // },
    // alert(value) {
    //   if (!value) {
    //     this.$store.dispatch('setError', false);
    //   }
    // },
  },
  created() {
    // if (this.loggedIn) {
    //   this.$router.push('/profile');
    // }
    this.initData()
  },
  methods: {
    async handleAddNewUser() {
      try {
        let addnewuser = AuthService.addnewuser(this.user)
        addnewuser = await addnewuser
        this.message = addnewuser.message
        this.successful = true
        this.user = new User('', '')
        this.users=addnewuser.data
        this.seiId=0
        this.selected=this.users[this.seiId].devices
        // this.$router.push('/');
      } catch (error) {
        console.log("Ошибочка вышла")
          this.message =
            (error.response && error.response.data) ||
            error.message ||
            error.toString();
          this.successful = false;
      }
    },
    async initData(){
      try {
        let usersdevices = AdminService.getUsersDevices()
        let devices = MnemoService.getDevices()
        usersdevices = await usersdevices;
        devices = await devices;

        this.users=usersdevices.data
        this.deviceTable=devices.data
        
        this.selected=this.users[this.seiId].devices

        // console.log('дождались', devices.data)
        // console.log('дождались users', usersdevices.data)

        // this.items = this.CreateTree(categories.data, devices.data);
      } catch (error) {
        // this.alert = true;
        console.log("ALARM!!!!!!!!!");
        throw error;
      }
      


      // console.log('users JSON:',JSON.stringify(this.users))
      // console.log('users:',this.users)


    },
    async updateUserDevice(){
      
      
      // let userdevices = AdminService.putUserDevices(data)
      console.log('send data')
      // console.log('user=',this.users[this.seiId].email)
      console.log('devices=',this.selected)
      console.log('user JSON before', JSON.stringify(this.users[this.seiId]))
      this.users[this.seiId].devices=this.selected
      let userdevices = AdminService.putUserDevices(this.users[this.seiId])
      userdevices = await userdevices
      if((await userdevices).status){
        console.log('user Ok')
      }
      

      console.log('user JSON after', JSON.stringify(this.users[this.seiId]))


    },
  }
};
</script>
