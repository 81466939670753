<template>
  <v-container>
    <v-data-table
        :headers="headers"
        :items="deviceTable"
        item-key="name"
        class="elevation-1"
        v-model="selected"
        show-select
        dense 
        disable-pagination 
        hide-default-footer
    >
      <template v-slot:top>
        <h3>Действие с выделенными:</h3>
        <v-btn @click="addSelected" > Добавить</v-btn>
        <v-btn @click="delSelected" > Удалить</v-btn>
      </template>
      <template v-slot:header.isExist >
        <v-btn text small @click="SelecteExisted" @click.stop>в базе</v-btn>
      </template>
      <template v-slot:item.isExist="{ item }">
          <v-simple-checkbox v-model="item.isExist" disabled>  </v-simple-checkbox>
      </template>
    </v-data-table>

  </v-container>
</template>



<script>
// import Axios from 'axios';
//  name: "Paramdescription",
import Device from '../../models/device';
import AdminService from "../../services/admin.service";

  export default {
    data () {
      return {
        singleSelect: false,
        ready:false,
        selected: [],
        headers: [
          {
            text: 'id',
            align: 'start',
            sortable: true,
            value: 'id',
          },
          { text: 'Название', value: 'name' },
          { text: 'в базе', value: 'isExist' },
          { text: 'Категория', value: 'category' },
        ],
        deviceTable: [],
      }
    },
    created() {
      this.initialize();
    },
    methods: {
      initialize() {
        this.myTableInit()
      },
      fillTable(arrDeviceOwencloud, arrDeviceFromDatabase, arrCategory){
        var table = [];
        var categArray = {}
        var deviceFromDBArray = {}
        var oneDevice = {
          'id':null,
          'name':'',
          'cat_id':null,
          'isExist':false,
          'category':''
        }

        arrCategory.forEach((arrElem) => {
          categArray[arrElem.id]=arrElem
        });

        arrDeviceFromDatabase.forEach((arrElem) => {
          deviceFromDBArray[arrElem.idd]=arrElem
        });

        arrDeviceOwencloud.forEach((arrElem) => {
          oneDevice = new Object()
          oneDevice.id=arrElem.idd
          oneDevice.name=arrElem.name
          oneDevice.cat_id = arrElem.category
          oneDevice.category = categArray[oneDevice.cat_id].name
          if(oneDevice.id in deviceFromDBArray){
            oneDevice.isExist=true
          }else{
            oneDevice.isExist=false
          }

          table.push(oneDevice)
        });

        // console.log('table: ',table)
        // console.log('table: ',this.desserts)
        return table;

      },
      async myTableInit() {
        try {
          let deviceListFromOwenCloud = AdminService.getDeviceFromOwencloud()
          let categoryListFromOwenCloud = AdminService.getCategogies()
          let deviceFromDatabase = AdminService.getDeviceFromDatabase()

          deviceListFromOwenCloud = await deviceListFromOwenCloud
          categoryListFromOwenCloud = await categoryListFromOwenCloud
          deviceFromDatabase = await deviceFromDatabase
         
          this.deviceTable = this.fillTable(deviceListFromOwenCloud.data, deviceFromDatabase.data, categoryListFromOwenCloud.data)
          this.ready=true

        } catch (error) {
          this.alert = true;
          console.log("ALARM!!!!!!!!!");
          throw error;
        }
      },
      SelecteExisted() {
        // var selec = [];
        this.deviceTable.forEach((arrElem) => {
          if(arrElem.isExist){
            this.selected.push(arrElem)
          }
        })
      },
      async delSelected() {
        var deviceList = []
        
        // console.log('Pressed', this.selected)
        this.selected.forEach(arrElem =>{
          // device = 
          deviceList.push(new Device(arrElem.id,arrElem.name,0,arrElem.cat_id))
        })
        console.log('selectedElem: ',deviceList)
        try {
              let setDevicesToDelete = AdminService.setDevicesToDelete(deviceList)

              setDevicesToDelete = await setDevicesToDelete
              this.selected = []
              console.log('rez: ', setDevicesToDelete.data)
              this.initialize()

        } catch (error) {
          this.alert = true;
          console.log("curSel ALARM!!!!!!!!!");
          throw error;
        }
      },
      async addSelected() {
        var deviceList = []
        
        // console.log('Pressed', this.selected)
        this.selected.forEach(arrElem =>{
          // device = 
          deviceList.push(new Device(arrElem.id,arrElem.name,0,arrElem.cat_id))
        })
        console.log('selectedElem: ',deviceList)
        try {
              let setDevicesToCreate = AdminService.setDevicesToCreate(deviceList)

              setDevicesToCreate = await setDevicesToCreate
              this.selected = []
              console.log('rez: ', setDevicesToCreate.data)

              this.initialize()

        } catch (error) {
          this.alert = true;
          console.log("curSel ALARM!!!!!!!!!");
          throw error;
        }

      },
    }
  }
  </script>

<style scoped>
a {
  color: #42b983;
}
</style>
