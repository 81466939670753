/*

import Vue from 'vue';
import App from './App.vue';
import { router } from './router';
import store from './store';
import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import VeeValidate from 'vee-validate';
import Vuex from 'vuex';
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import {
  faHome,
  faUser,
  faUserPlus,
  faSignInAlt,
  faSignOutAlt
} from '@fortawesome/free-solid-svg-icons';

library.add(faHome, faUser, faUserPlus, faSignInAlt, faSignOutAlt);

Vue.config.productionTip = false;

Vue.use(VeeValidate);
Vue.component('font-awesome-icon', FontAwesomeIcon);

Vue.use(Vuex);

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app');

*/

import Vue from 'vue';
import * as axios from 'axios';
import App from './App.vue';
import router from './router';
import store from './store';
import * as VeeValidate from 'vee-validate';
import Vuex from 'vuex';
import vuetify from './plugins/vuetify';
// import { mdiChartAreaspline } from '@mdi/js';

Vue.prototype.$axios = axios.create({
  //  baseURL: `http://localhost:9000`,
    baseURL: `/`,
    // timeout: 1000
});

axios.interceptors.response.use((response) => Promise.resolve(response),
  (error) => {
    if (error.response.status === 401) {
      console.log('Unauthorized, logging out ...');
      store.dispatch('auth/logout');
      router.replace('signIn');
      return Promise.reject(error);
    }
    return Promise.reject(error.response);
  });

Vue.use(VeeValidate);
Vue.use(Vuex);

new Vue({
  router,
  store,
  vuetify,
  render(h) { return h(App); },
}).$mount('#app');


