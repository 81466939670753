<template>
  <v-container>
    <v-layout row>
      <v-flex
        xs12
        class="text-center"
        mt-5
      >
        <h3>Вход в систему</h3>
      </v-flex>
      <v-flex
        xs12
        sm6
        offset-sm3
        mt-3
      >
        <form @submit.prevent="handleLogin">
          <v-layout column>
            <v-flex>
              <v-alert
                v-model="alert"
                type="error"
                dismissible
              >
                {{ error }}
              </v-alert>
            </v-flex>
            <v-flex>
              <v-text-field
                id="username"
                v-model="user.username"
                name="username"
                label="Логин"
                type="text"
                required
              />
            </v-flex>
            <v-flex>
              <v-text-field
                id="password"
                v-model="user.password"
                name="password"
                label="Пароль"
                type="password"
                required
              />
            </v-flex>
            <v-flex
              class="text-center"
              mt-5
            >
              <v-btn
                type="submit"
                :disabled="loading"
                dark
                color="teal lighten-1"
                autofocus
              >
                Вход
              </v-btn>
            </v-flex>
          </v-layout>
        </form>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import User from '../models/user';

export default {
  name: 'Login',
  data() {
    return {
      user: new User('', ''),
      loading: false,
      message: '',
      alert: false,
    };
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
    error() {
      return this.$store.getters.getError;
    },
  },
  watch: {
    error(value) {
      if (value) {
        this.alert = true;
      }
    },
    alert(value) {
      if (!value) {
        this.$store.dispatch('setError', false);
      }
    },
  },
  created() {
    if (this.loggedIn) {
      this.$router.push('/profile');
    }
  },
  methods: {
    handleLogin() {
      this.loading = true;
      this.$validator.validateAll().then(isValid => {
        if (!isValid) {
          this.loading = false;
          return;
        }

        if (this.user.username && this.user.password) {
          this.$store.dispatch('auth/login', this.user).then(
            () => {
              this.$router.push('/mnemo');
            },
            error => {
              console.log('login err')
              this.loading = false;
              this.message =
                (error.response && error.response.data) ||
                error.message ||
                error.toString();
            }
          );
        }
      });
    }
  }
};
</script>
