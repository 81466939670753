<style>
#mnemo {
  position: absolute;
  /* background-color: brown; */
}
#backgranudimage-0{
    position: absolute;
    top: 0;
    left: 0;
    content: url(../img/image2.png);  
    width: 1500px;
    height: 721px;
     z-index: 1;
}
#backgranudimage-1{
    position: absolute;
    top: 0;
    left: 0;
    content: url(../img/image_gvs1_no_pump.png);  
    width: 1500px;
    height: 721px;
     z-index: 1;
}
#backgranudimage-2{
    position: absolute;
    top: 0;
    left: 0;
    content: url(../img/image_gvs2.png);  
    width: 1500px;
    height: 721px;
     z-index: 1;
}
#backgranudimage-3{
    position: absolute;
    top: 0;
    left: 0;
    content: url(../img/image_termo.png);
    width: 1500px;
    height: 455px;
     z-index: 1;
}
.mnemo2_elem1{
    position: absolute;
    top: 287px;
    left: 1205px;
    text-align: center;
    width: 63px;
    height: 24px;
    z-index: 2;
    /* background-color: brown; */
}
.mnemo2_elem2{
    position: absolute;
    top: 604px;
    left: 924px;
    text-align: center;
    width: 63px;
    height: 24px;
    z-index: 2;
    /* background-color: brown; */
}
.mnemo2_elem3{
    position: absolute;
    top: 338px;
    left: 504px;
    text-align: center;
    width: 63px;
    height: 24px;
    z-index: 2;
    /* background-color: brown; */
}
.mnemo2_elem4{
    position: absolute;
    top: 604px;
    left: 1006px;
    text-align: center;
    width: 63px;
    height: 24px;
    z-index: 2;
    /* background-color: brown; */
}
.mnemo2_elem5{
    position: absolute;
    top: 328px;
    left: 1276px;
    text-align: center;
    width: 63px;
    height: 24px;
    z-index: 2;
    /* background-color: brown; */
}
.mnemo2_elem6{
    position: absolute;
    top: 74px;
    left: 375px;
    text-align: center;
    width: 63px;
    height: 24px;
    z-index: 2;
    /* background-color: brown; */
}
.mnemo2_elem7{
    position: absolute;
    top: 50px;
    left: 690px;
    text-align: center;
    width: 63px;
    height: 24px;
    z-index: 2;
    /* background-color: brown; */
}
.mnemo2_elem_hidro{
    position: absolute;
    top: 76px;
    left: 690px;
    text-align: center;
    width: 63px;
    height: 24px;
    z-index: 2;
    /* background-color: brown; */
}
.mnemo2_elem_batt{
    position: absolute;
    top: 102px;
    left: 690px;
    text-align: center;
    width: 63px;
    height: 24px;
    z-index: 2;
    /* background-color: brown; */
}
.mnemo2_elem8{
    position: absolute;
    top: 120px;
    left: 130px;
    text-align: center;
    width: 63px;
    height: 24px;
    z-index: 2;
    /* background-color: brown; */
}
.mnemo2_elem9{
    position: absolute;
    top: 399px;
    left: 580px;
    text-align: center;
    width: 63px;
    height: 24px;
    z-index: 2;
    /* background-color: brown; */
}
.mnemo2_elem10{
    position: absolute;
    top: 120px;
    left: 130px;
    text-align: center;
    width: 63px;
    height: 24px;
    z-index: 2;
    /* background-color: brown; */
}
.mnemo2_elem11{
    position: absolute;
    top: 120px;
    left: 130px;
    text-align: center;
    width: 63px;
    height: 24px;
    z-index: 2;
    /* background-color: brown; */
}
.mnemo2_elem12{
    position: absolute;
    top: 237px;
    left: 783px;
    text-align: center;
    width: 63px;
    height: 24px;
    z-index: 2;
    /* background-color: brown; */
}
.mnemo2_elem13{
    position: absolute;
    top: 74px;
    left: 288px;
    text-align: center;
    width: 63px;
    height: 24px;
    z-index: 2;
    /* background-color: brown; */
}
.mnemo2_elem14{
    position: absolute;
    top: 120px;
    left: 130px;
    text-align: center;
    width: 63px;
    height: 24px;
    z-index: 2;
    /* background-color: brown; */
}
.mnemo2_elem15{
    position: absolute;
    top: 120px;
    left: 130px;
    text-align: center;
    width: 63px;
    height: 24px;
    z-index: 2;
    /* background-color: brown; */
}
.mnemo2_elem16{
    position: absolute;
    top: 120px;
    left: 130px;
    text-align: center;
    width: 63px;
    height: 24px;
    z-index: 2;
    /* background-color: brown; */
}
.mnemo2_elem17{
    position: absolute;
    top: 364px;
    left: 647px;
    text-align: center;
    width: 63px;
    height: 24px;
    z-index: 2;
    /* background-color: brown; */
}
.mnemo2_elem20{
    position: absolute;
    top: 470px;
    left: 738px;
    text-align: center;
    width: 63px;
    height: 24px;
    z-index: 2;
    /* background-color: brown; */
}
.mnemo2_elem21{
    position: absolute;
    top: 675px;
    left: 836px;
    text-align: center;
    width: 63px;
    height: 24px;
    z-index: 2;
    /* background-color: brown; */
}
.mnemo2_elem22{
    position: absolute;
    top: 610px;
    left: 674px;
    text-align: center;
    width: 63px;
    height: 24px;
    z-index: 2;
    /* background-color: brown; */
}
.mnemo2_elem12_2{
    position: absolute;
    top: 42px;
    left: 53px;
    text-align: center;
    width: 63px;
    height: 24px;
    z-index: 2;
    /* background-color: brown; */
}
.mnemo2_elem14_2{
    position: absolute;
    top: 71px;
    left: 53px;
    text-align: center;
    width: 63px;
    height: 24px;
    z-index: 2;
    /* background-color: brown; */
}
.mnemo2_elem15_2{
    position: absolute;
    top: 99px;
    left: 53px;
    text-align: center;
    width: 63px;
    height: 24px;
    z-index: 2;
    /* background-color: brown; */
}
.mnemo2_elem17_2{
    position: absolute;
    top: 15px;
    left: 53px;
    text-align: center;
    width: 63px;
    height: 24px;
    z-index: 2;
    /* background-color: brown; */
}
.mnemo2_elem18_2{
    position: absolute;
    top: 127px;
    left: 53px;
    text-align: center;
    width: 63px;
    height: 24px;
    z-index: 2;
    /* background-color: brown; */
}
.mnemo2_elem19_2{
    position: absolute;
    top: 155px;
    left: 53px;
    text-align: center;
    width: 63px;
    height: 24px;
    z-index: 2;
    /* background-color: brown; */
}
.mnemo2_elemdP{
    position: absolute;
    top: 387px;
    left: 1232px;
    text-align: center;
    width: 63px;
    height: 24px;
    z-index: 2;
    /* background-color: brown; */
}

/* заполняется левый блок квадратов */
#mnemo2_demo11{
    position: absolute;
    font-size: 90%;
    top: 242px;
    left: 45px;
     z-index: 2;
}
#mnemo2_demo12{
    position: absolute;
    font-size: 90%;
    top: 270px;
    left: 45px;
     z-index: 2;
}
#mnemo2_demo13{
    position: absolute;
    font-size: 90%;
    top: 302px;
    left: 45px;
     z-index: 2;
}
#mnemo2_demo14{
    position: absolute;
    font-size: 90%;
    top: 334px;
    left: 45px;
     z-index: 2;
}
#mnemo2_demo15{
    position: absolute;
    font-size: 90%;
    top: 400px;
    left: 45px;
     z-index: 2;
}
#mnemo2_demo16{
    position: absolute;
    font-size: 90%;
    top: 432px;
    left: 45px;
     z-index: 2;
}
#mnemo2_demo17{
    position: absolute;
    font-size: 90%;
    top: 460px;
    left: 45px;
     z-index: 2;
}
#mnemo2_demo18{
    position: absolute;
    font-size: 90%;
    top: 490px;
    left: 45px;
     z-index: 2;
}
/* заполняется средний блок квадратов */
#mnemo2_demo21{
    position: absolute;
    font-size: 90%;
    top: 242px;
    left: 90px;
     z-index: 2;
}
#mnemo2_demo22{
    position: absolute;
    font-size: 90%;
    top: 270px;
    left: 90px;
     z-index: 2;
}
#mnemo2_demo23{
    position: absolute;
    font-size: 90%;
    top: 302px;
    left: 90px;
     z-index: 2;
}
#mnemo2_demo24{
    position: absolute;
    font-size: 90%;
    top: 334px;
    left: 90px;
     z-index: 2;
}
#mnemo2_demo25{
    position: absolute;
    font-size: 90%;
    top: 400px;
    left: 90px;
     z-index: 2;
}
#mnemo2_demo26{
    position: absolute;
    font-size: 90%;
    top: 432px;
    left: 90px;
     z-index: 2;
}
#mnemo2_demo27{
    position: absolute;
    font-size: 90%;
    top: 460px;
    left: 90px;
     z-index: 2;
}
#mnemo2_demo28{
    position: absolute;
    font-size: 90%;
    top: 490px;
    left: 90px;
     z-index: 2;
}
/* заполняется правый блок квадратов */
#mnemo2_demo31{
    position: absolute;
    font-size: 90%;
    top: 242px;
    left: 135px;
     z-index: 2;
}
#mnemo2_demo32{
    position: absolute;
    font-size: 90%;
    top: 270px;
    left: 135px;
     z-index: 2;
}
#mnemo2_demo33{
    position: absolute;
    font-size: 90%;
    top: 302px;
    left: 135px;
     z-index: 2;
}
#mnemo2_demo34{
    position: absolute;
    font-size: 90%;
    top: 334px;
    left: 135px;
     z-index: 2;
}
#mnemo2_demo35{
    position: absolute;
    font-size: 90%;
    top: 400px;
    left: 135px;
     z-index: 2;
}
#mnemo2_demo36{
    position: absolute;
    font-size: 90%;
    top: 432px;
    left: 135px;
     z-index: 2;
}
#mnemo2_demo37{
    position: absolute;
    font-size: 90%;
    top: 460px;
    left: 135px;
     z-index: 2;
}
#mnemo2_demo38{
    position: absolute;
    font-size: 90%;
    top: 490px;
    left: 135px;
     z-index: 2;
}
.mnemo2_demo_nasos{
  /* Насос */
    position: absolute;
    /* font-size: 8pt; */
    top: 241px;
    left: 952px;
    text-align: center;
    width: 64px;
    height: 24px;
    z-index: 2;
    /* background-color: brown; */
}

.mnemo2_btn1{
    position: absolute;
    top: 13px;
    left: 24px;
     z-index: 2;
}
.mnemo2_btn2{
    position: absolute;
    top: 42px;
    left: 24px;
     z-index: 2;
}
.mnemo2_btn3{
    position: absolute;
    top: 70px;
    left: 24px;
     z-index: 2;
}
.mnemo2_btn4{
    position: absolute;
    top: 98px;
    left: 24px;
     z-index: 2;
}
.mnemo2_btn5{
    position: absolute;
    top: 126px;
    left: 24px;
     z-index: 2;
}
.mnemo2_btn6{
    position: absolute;
    top: 154px;
    left: 24px;
     z-index: 2;
}
.mnemo2_btn7{
    position: absolute;
    top: 609px;
    left: 760px;
     z-index: 2;
}
.mnemo2_selectel{
    z-index: 2;
    width: 400px;
    top: 300px;
}
.mnemo2_btnPanel{
    position: absolute;
    top: 20px;
    left: 750px;
    z-index: 2;
}
.mnemo2_btnArchive{
    position: absolute;
    top: 20px;
    left: 800px;
    z-index: 2;
}

.mnemo0_elem1{
    position: absolute;
    top: 363px;
    left: 1186px;
    text-align: center;
    width: 63px;
    height: 24px;
    z-index: 2;
    /* background-color: brown; */
}
.mnemo0_elem2{
    position: absolute;
    top: 628px;
    left: 915px;
    text-align: center;
    width: 63px;
    height: 24px;
    z-index: 2;
    /* background-color: brown; */
}
.mnemo0_elem3{
    position: absolute;
    top: 405px;
    left: 512px;
    text-align: center;
    width: 63px;
    height: 24px;
    z-index: 2;
    /* background-color: brown; */
}
.mnemo0_elem4{
    position: absolute;
    top: 628px;
    left: 996px;
    text-align: center;
    width: 63px;
    height: 24px;
    z-index: 2;
    /* background-color: brown; */
}
.mnemo0_elem5{
    position: absolute;
    top: 397px;
    left: 1254px;
    text-align: center;
    width: 63px;
    height: 24px;
    z-index: 2;
    /* background-color: brown; */
}
.mnemo0_elem6{
    position: absolute;
    top: 70px;
    left: 379px;
    text-align: center;
    width: 63px;
    height: 24px;
    z-index: 2;
    /* background-color: brown; */
}
.mnemo0_elem7{
    position: absolute;
    top: 120px;
    left: 430px;
    text-align: center;
    width: 63px;
    height: 24px;
    z-index: 2;
    /* background-color: brown; */
}
.mnemo0_elem8{
    position: absolute;
    top: 120px;
    left: 130px;
    text-align: center;
    width: 63px;
    height: 24px;
    z-index: 2;
    /* background-color: brown; */
}
.mnemo0_elem9{
    position: absolute;
    top: 468px;
    left: 583px;
    text-align: center;
    width: 63px;
    height: 24px;
    z-index: 2;
    /* background-color: brown; */
}
.mnemo0_elem10{
    position: absolute;
    top: 120px;
    left: 130px;
    text-align: center;
    width: 63px;
    height: 24px;
    z-index: 2;
    /* background-color: brown; */
}
.mnemo0_elem11{
    position: absolute;
    top: 120px;
    left: 130px;
    text-align: center;
    width: 63px;
    height: 24px;
    z-index: 2;
    /* background-color: brown; */
}
.mnemo0_elem12{
    position: absolute;
    top: 237px;
    left: 783px;
    text-align: center;
    width: 63px;
    height: 24px;
    z-index: 2;
    /* background-color: brown; */
}
.mnemo0_elem13{
    position: absolute;
    top: 70px;
    left: 298px;
    text-align: center;
    width: 63px;
    height: 24px;
    z-index: 2;
    /* background-color: brown; */
}
.mnemo0_elem14{
    position: absolute;
    top: 120px;
    left: 130px;
    text-align: center;
    width: 63px;
    height: 24px;
    z-index: 2;
    /* background-color: brown; */
}
.mnemo0_elem15{
    position: absolute;
    top: 120px;
    left: 130px;
    text-align: center;
    width: 63px;
    height: 24px;
    z-index: 2;
    /* background-color: brown; */
}
.mnemo0_elem16{
    position: absolute;
    top: 120px;
    left: 130px;
    text-align: center;
    width: 63px;
    height: 24px;
    z-index: 2;
    /* background-color: brown; */
}
.mnemo0_elem17{
    position: absolute;
    top: 364px;
    left: 647px;
    text-align: center;
    width: 63px;
    height: 24px;
    z-index: 2;
    /* background-color: brown; */
}
.mnemo0_elem12_2{
    position: absolute;
    top: 53px;
    left: 77px;
    text-align: center;
    width: 63px;
    height: 24px;
    z-index: 2;
    /* background-color: brown; */
}
.mnemo0_elem14_2{
    position: absolute;
    top: 81px;
    left: 77px;
    text-align: center;
    width: 63px;
    height: 24px;
    z-index: 2;
    /* background-color: brown; */
}
.mnemo0_elem15_2{
    position: absolute;
    top: 109px;
    left: 77px;
    text-align: center;
    width: 63px;
    height: 24px;
    z-index: 2;
    /* background-color: brown; */
}
.mnemo0_elem17_2{
    position: absolute;
    top: 25px;
    left: 77px;
    text-align: center;
    width: 63px;
    height: 24px;
    z-index: 2;
    /* background-color: brown; */
}
.mnemo0_elem18_2{
    position: absolute;
    top: 137px;
    left: 77px;
    text-align: center;
    width: 63px;
    height: 24px;
    z-index: 2;
    /* background-color: brown; */
}
.mnemo0_elem19_2{
    position: absolute;
    top: 165px;
    left: 77px;
    text-align: center;
    width: 63px;
    height: 24px;
    z-index: 2;
    /* background-color: brown; */
}
.mnemo0_elemdP{
    position: absolute;
    top: 460px;
    left: 1220px;
    text-align: center;
    width: 63px;
    height: 24px;
    z-index: 2;
    /* background-color: brown; */
}

/* заполняется левый блок квадратов */
#mnemo0_demo11{
    position: absolute;
    font-size: 90%;
    top: 305px;
    left: 72px;
     z-index: 2;
}
#mnemo0_demo12{
    position: absolute;
    font-size: 90%;
    top: 335px;
    left: 72px;
     z-index: 2;
}
#mnemo0_demo13{
    position: absolute;
    font-size: 90%;
    top: 367px;
    left: 72px;
     z-index: 2;
}
#mnemo0_demo14{
    position: absolute;
    font-size: 90%;
    top: 399px;
    left: 72px;
     z-index: 2;
}
#mnemo0_demo15{
    position: absolute;
    font-size: 90%;
    top: 470px;
    left: 72px;
     z-index: 2;
}
#mnemo0_demo16{
    position: absolute;
    font-size: 90%;
    top: 500px;
    left: 72px;
     z-index: 2;
}
#mnemo0_demo17{
    position: absolute;
    font-size: 90%;
    top: 530px;
    left: 72px;
     z-index: 2;
}
#mnemo0_demo18{
    position: absolute;
    font-size: 90%;
    top: 560px;
    left: 72px;
     z-index: 2;
}
/* заполняется средний блок квадратов */
#mnemo0_demo21{
    position: absolute;
    font-size: 90%;
    top: 305px;
    left: 120px;
     z-index: 2;
}
#mnemo0_demo22{
    position: absolute;
    font-size: 90%;
    top: 335px;
    left: 120px;
     z-index: 2;
}
#mnemo0_demo23{
    position: absolute;
    font-size: 90%;
    top: 367px;
    left: 120px;
     z-index: 2;
}
#mnemo0_demo24{
    position: absolute;
    font-size: 90%;
    top: 399px;
    left: 120px;
     z-index: 2;
}
#mnemo0_demo25{
    position: absolute;
    font-size: 90%;
    top: 470px;
    left: 120px;
     z-index: 2;
}
#mnemo0_demo26{
    position: absolute;
    font-size: 90%;
    top: 500px;
    left: 120px;
     z-index: 2;
}
#mnemo0_demo27{
    position: absolute;
    font-size: 90%;
    top: 530px;
    left: 120px;
     z-index: 2;
}
#mnemo0_demo28{
    position: absolute;
    font-size: 90%;
    top: 560px;
    left: 120px;
     z-index: 2;
}
/* заполняется правый блок квадратов */
#mnemo0_demo31{
    position: absolute;
    font-size: 90%;
    top: 305px;
    left: 160px;
     z-index: 2;
}
#mnemo0_demo32{
    position: absolute;
    font-size: 90%;
    top: 335px;
    left: 160px;
     z-index: 2;
}
#mnemo0_demo33{
    position: absolute;
    font-size: 90%;
    top: 367px;
    left: 160px;
     z-index: 2;
}
#mnemo0_demo34{
    position: absolute;
    font-size: 90%;
    top: 399px;
    left: 160px;
     z-index: 2;
}
#mnemo0_demo35{
    position: absolute;
    font-size: 90%;
    top: 470px;
    left: 160px;
     z-index: 2;
}
#mnemo0_demo36{
    position: absolute;
    font-size: 90%;
    top: 500px;
    left: 160px;
     z-index: 2;
}
#mnemo0_demo37{
    position: absolute;
    font-size: 90%;
    top: 530px;
    left: 160px;
     z-index: 2;
}
#mnemo0_demo38{
    position: absolute;
    font-size: 90%;
    top: 560px;
    left: 160px;
     z-index: 2;
}

.mnemo0_demo_nasos{
  /* Насос */
    position: absolute;
    /* font-size: 8pt; */
    top: 304px;
    left: 954px;
    text-align: center;
    width: 56px;
    height: 24px;
    z-index: 2;
    /* background-color: brown; */
}

.mnemo0_btn1{
    position: absolute;
    top: 24px;
    left: 51px;
     z-index: 2;
}
.mnemo0_btn2{
    position: absolute;
    top: 52px;
    left: 51px;
     z-index: 2;
}
.mnemo0_btn3{
    position: absolute;
    top: 80px;
    left: 51px;
     z-index: 2;
}
.mnemo0_btn4{
    position: absolute;
    top: 108px;
    left: 51px;
     z-index: 2;
}
.mnemo0_btn5{
    position: absolute;
    top: 136px;
    left: 51px;
     z-index: 2;
}
.mnemo0_btn6{
    position: absolute;
    top: 164px;
    left: 51px;
     z-index: 2;
}
.mnemo0_selectel{
    z-index: 2;
    width: 400px;
    top: 300px;
}
.mnemo0_btnPanel{
    position: absolute;
    top: 20px;
    left: 1070px;
    z-index: 2;
}
.mnemo0_btnArchive{
    position: absolute;
    top: 20px;
    left: 1170px;
    z-index: 2;
}
.mnemo0_wifitermo{
  position: absolute;
  top: 70px;
  left: 650px;
     z-index: 2;
}
.mnemo2_wifitermo{
  position: absolute;
  top: 45px;
  left: 605px;
     z-index: 2;
}
.mnemo3_wifitermo{
  position: absolute;
  top: 250px;
  left: 230px;
     z-index: 2;
}

.mnemo3_btnPanel{
    position: absolute;
    top: 20px;
    left: 750px;
    z-index: 2;
}
.mnemo3_btnArchive{
    position: absolute;
    top: 20px;
    left: 800px;
    z-index: 2;
}
.mnemo3_selectel{
    z-index: 2;
    width: 400px;
    top: 300px;
}

.mnemo3_elem7{
    position: absolute;
    top: 345px;
    left: 432px;
    text-align: center;
    width: 63px;
    height: 24px;
    z-index: 2;
    /* background-color: brown; */
}
.mnemo3_elem_hidro{
    position: absolute;
    top: 345px;
    left: 632px;
    text-align: center;
    width: 63px;
    height: 24px;
    z-index: 2;
    /* background-color: brown; */
}
.mnemo3_elem_batt{
    position: absolute;
    top: 345px;
    left: 834px;
    text-align: center;
    width: 63px;
    height: 24px;
    z-index: 2;
    /* background-color: brown; */
}

</style>

<template>
  <v-container id="mnemo"  v-if="mnemoTypeis==0">
    <div id="backgranudimage-0"/>
    <div class="mnemo0_wifitermo" >
      <v-img
          src="../img/wifitermo.png"
          class="img-button"
          max-width="100"
          max-height="100"
      ></v-img>
    </div>
    <v-tooltip top>
    <template v-slot:activator="{ on }">
      <span v-on="on" class="mnemo0_btnPanel" >
        <v-btn
          color="teal lighten-1"
          @click="showSelect"
          icon
          large
        >
          <v-icon>mdi-map-search-outline</v-icon>
        </v-btn>
        </span>
    </template>
    Выбор устройства
  </v-tooltip>
  <v-tooltip top>
    <template v-slot:activator="{ on }">
      <span v-on="on" class="mnemo0_btnArchive" >
        <v-btn
          color="teal lighten-1"
          @click="downloadArchive"
          icon
          large
        >
          <v-icon>mdi-chart-areaspline</v-icon>
        </v-btn>
        </span>
    </template>
    Скачать архив значений
  </v-tooltip>
  <v-navigation-drawer class="mnemo0_selectel"
    v-model="drawer"
    app
  >
    <v-treeview 
      :items="items" 
      open-all 
      :open-on-click="true"
      activatable
      item-key="idd"
    > 
      <template slot="label" slot-scope="{ item }">
        <a @click="openDialog(item)">{{ item.name }}</a>
      </template>
    </v-treeview>
  </v-navigation-drawer>

  <v-tooltip top>
    <template v-slot:activator="{ on }">
      <span v-on="on" class="mnemo0_elem1" >{{param[1].value}}кПа</span>
    </template>
    Mod_P1_подачи
  </v-tooltip>
  <v-tooltip top>
    <template v-slot:activator="{ on }">
      <span v-on="on" class="mnemo0_elem2" >{{param[2].value}}кПа</span>
    </template>
    Mod_P2_обратки
  </v-tooltip>
  <v-tooltip top>
    <template v-slot:activator="{ on }">
      <span v-on="on" class="mnemo0_elem3" >{{param[3].value}}&#8451;</span>
    </template>
    Mod_T1подачи
  </v-tooltip>
  <v-tooltip top>
    <template v-slot:activator="{ on }">
      <span v-on="on" class="mnemo0_elem4" >{{param[4].value}}&#8451;</span>
    </template>
    Mod_T2обратки
  </v-tooltip>
  <v-tooltip top>
    <template v-slot:activator="{ on }">
      <span v-on="on" class="mnemo0_elem5" >{{param[5].value}}&#8451;</span>
    </template>
    Mod_T3смешения
  </v-tooltip>
  <v-tooltip top>
    <template v-slot:activator="{ on }" v-if="param[11].value==0">
      <span v-on="on" class="mnemo0_elem6" >{{param[6].value}}&#8451;</span>
    </template>
    <template v-slot:activator="{ on }" v-else>
      <span v-on="on" class="mnemo0_elem6" >{{param[7].value}}&#8451;</span>
    </template>
    T_в помещении
  </v-tooltip>
  <v-tooltip top>
    <template v-slot:activator="{ on }">
      <span v-on="on" class="mnemo0_elem9" >{{param[9].value}}%</span>
    </template>
    Положение клапана
  </v-tooltip>
  <v-tooltip top>
    <template v-slot:activator="{ on }">
      <span v-on="on" class="mnemo0_elem12" >{{param[12].value}}&#8451;</span>
    </template>
    t, &#8451; смены режима
  </v-tooltip>
  <v-tooltip top>
    <template v-slot:activator="{ on }">
      <span v-on="on" class="mnemo0_elem12_2" >{{param[12].value}}&#8451;</span>
    </template>
    t, &#8451; смены режима
  </v-tooltip>
  <v-tooltip top>
    <template v-slot:activator="{ on }">
      <span v-on="on" class="mnemo0_elem13" >{{param[13].value}}&#8451;</span>
    </template>
    Т_улицы
  </v-tooltip>
  <v-tooltip top>
    <template v-slot:activator="{ on }">
      <span v-on="on" class="mnemo0_elem14_2" >{{param[14].value}}&#8451;</span>
    </template>
    Температура комфорт
  </v-tooltip>
  <v-tooltip top>
    <template v-slot:activator="{ on }">
      <span v-on="on" class="mnemo0_elem15_2" >{{param[15].value}}&#8451;</span>
    </template>
    Температура эконом
  </v-tooltip>
  <v-tooltip top>
    <template v-slot:activator="{ on }">
      <span v-on="on" class="mnemo0_elem17" >{{param[17].value}}кПа</span>
    </template>
    Уставка по давлению
  </v-tooltip>
  <v-tooltip top>
    <template v-slot:activator="{ on }">
      <span v-on="on" class="mnemo0_elem17_2" >{{param[17].value}}кПа</span>
    </template>
    Уставка по давлению
  </v-tooltip>
  <v-tooltip top>
    <template v-slot:activator="{ on }">
      <span v-on="on" class="mnemo0_elem18_2" >{{param[18].value}}</span>
    </template>
    Время комфорт
  </v-tooltip>
  <v-tooltip top>
    <template v-slot:activator="{ on }">
      <span v-on="on" class="mnemo0_elem19_2" >{{param[19].value}}</span>
    </template>
    Время эконом
  </v-tooltip>
  <v-tooltip top>
    <template v-slot:activator="{ on }">
      <span v-on="on" class="mnemo0_elemdP" >{{param[1].value-param[2].value}}кПа</span>
    </template>
    &#916;P, после элеватора
  </v-tooltip>
  <div id="mnemo0_demo11">P,кПа</div>
  <div id="mnemo0_demo12">T,&#8451;</div>
  <div id="mnemo0_demo13">G</div>
  <div id="mnemo0_demo14">
    Q
  </div>
  <div id="mnemo0_demo15">
    Qтек
  </div>
  <div id="mnemo0_demo16">
    Qчас
  </div>
  <div id="mnemo0_demo17">
    ---
  </div>
  <div id="mnemo0_demo18">
    ---
  </div>
  <div id="mnemo0_demo21">
    {{param[1].value}}
  </div>
  <div id="mnemo0_demo22">
    {{param[3].value}}
  </div>
  <div id="mnemo0_demo23">
    408
  </div>
  <div id="mnemo0_demo24">
    23,6
  </div>
  <div id="mnemo0_demo25">
    ---
  </div>
  <div id="mnemo0_demo26">
    ---
  </div>
  <div id="mnemo0_demo27">
    ---
  </div>
  <div id="mnemo0_demo28">
    ---
  </div>
  <div id="mnemo0_demo31">
    {{param[2].value}}
  </div>
  <div id="mnemo0_demo32">
    {{param[4].value}}
  </div>
  <div id="mnemo0_demo33">
    406
  </div>
  <div id="mnemo0_demo34">
    20,6
  </div>
  <div id="mnemo0_demo35">
    ---
  </div>
  <div id="mnemo0_demo36">
    ---
  </div>
  <div id="mnemo0_demo37">
    ---
  </div>
  <div id="mnemo0_demo38">
    ---
  </div>
  <v-tooltip top>
    <template v-slot:activator="{ on }">
      <span v-on="on" class="mnemo0_demo_nasos" >{{param[23].value}}%</span>
    </template>
    Нагрузка насоса
  </v-tooltip>


      <!-- <v-btn
        dark
        color="teal lighten-1"
        class="mnemo0_btn1"
        @click="getParamIdInformation"
        
      >
        режим
      </v-btn> -->
      <div class="mnemo0_btn1">
        <set-param title="Уставка по давлению" :param="param[17]" />
      </div>
      <div class="mnemo0_btn2">
        <set-param title="Температура смены режима" :param="param[12]" />
      </div>
      <div class="mnemo0_btn3">
        <set-param title="Температура Комфорт" :param="param[14]" />
      </div>
      <div class="mnemo0_btn4">
        <set-param title="Температура эконом" :param="param[15]" />
      </div>
      <div class="mnemo0_btn5">
        <set-param title="Время Комфорт" :param="param[18]" />
      </div>
      <div class="mnemo0_btn6">
        <set-param title="Время эконом" :param="param[19]" />
      </div>
  </v-container>
  <v-container id="mnemo" v-else-if="mnemoTypeis==1">
    <div id="backgranudimage-1" />
    <v-tooltip top>
      <template v-slot:activator="{ on }">
        <span v-on="on" class="mnemo2_btnPanel" >
          <v-btn
            color="teal lighten-1"
            @click="showSelect"
            icon
            large
          >
            <v-icon>mdi-map-search-outline</v-icon>
          </v-btn>
          </span>
      </template>
      Выбор устройства
    </v-tooltip>
    <v-navigation-drawer class="mnemo2_selectel"
      v-model="drawer"
      app
    >
      <v-treeview 
        :items="items" 
        open-all 
        :open-on-click="true"
        activatable
        item-key="idd"
      > 
        <template slot="label" slot-scope="{ item }">
          <a @click="openDialog(item)">{{ item.name }}</a>
        </template>
      </v-treeview>
    </v-navigation-drawer>

    <v-tooltip top>
      <template v-slot:activator="{ on }">
        <span v-on="on" class="mnemo2_elem1" >{{param[1].value}}кПа</span>
      </template>
      Mod_P1_подачи
    </v-tooltip>
    <v-tooltip top>
      <template v-slot:activator="{ on }">
        <span v-on="on" class="mnemo2_elem2" >{{param[2].value}}кПа</span>
      </template>
      Mod_P2_обратки
    </v-tooltip>
    <v-tooltip top>
      <template v-slot:activator="{ on }">
        <span v-on="on" class="mnemo2_elem3" >{{param[3].value}}&#8451;</span>
      </template>
      Mod_T1подачи
    </v-tooltip>
    <v-tooltip top>
      <template v-slot:activator="{ on }">
        <span v-on="on" class="mnemo2_elem4" >{{param[4].value}}&#8451;</span>
      </template>
      Mod_T2обратки
    </v-tooltip>
    <v-tooltip top>
      <template v-slot:activator="{ on }">
        <span v-on="on" class="mnemo2_elem5" >{{param[5].value}}&#8451;</span>
      </template>
      Mod_T3смешения
    </v-tooltip>
    <v-tooltip top>
      <template v-slot:activator="{ on }">
        <span v-on="on" class="mnemo2_elem6" >{{param[6].value}}&#8451;</span>
      </template>
      T_в помещении
    </v-tooltip>
    <v-tooltip top>
      <!-- <template v-slot:activator="{ on }" v-if="param[11].value==1">
        <span v-on="on" class="mnemo2_elem7" >{{param[7].value}}&#8451;</span>
      </template> -->
      <template v-slot:activator="{ on }">
        <span v-on="on" class="mnemo2_elem7" >{{param[7].value}}&#8451;</span>
      </template>
      T_в помещении беспроводной датчик
    </v-tooltip>
    <v-tooltip top>
      <!-- <template v-slot:activator="{ on }" v-if="param[11].value==1">
        <span v-on="on" class="mnemo2_elem_hidro" >{{param[100].value}}%</span>
      </template> -->
      <template v-slot:activator="{ on }">
        <span v-on="on" class="mnemo2_elem_hidro" >{{param[100].value}}%</span>
      </template>
      Влажность помещении беспроводной датчик
    </v-tooltip>
    <v-tooltip top>
      <!-- <template v-slot:activator="{ on }" v-if="param[11].value==1">
        <span v-on="on" class="mnemo2_elem_batt" >{{param[101].value}}В</span>
      </template> -->
      <template v-slot:activator="{ on }">
        <span v-on="on" class="mnemo2_elem_batt" >{{param[101].value}}В</span>
      </template>
      Заряд батареи беспроводной датчик
    </v-tooltip>
    <v-tooltip top>
      <template v-slot:activator="{ on }">
        <span v-on="on" class="mnemo2_elem9" >{{param[9].value}}%</span>
      </template>
      Положение клапана
    </v-tooltip>
    <!-- <v-tooltip top>
      <template v-slot:activator="{ on }">
        <span v-on="on" class="mnemo2_elem12" >{{param[12].value}}&#8451;</span>
      </template>
      t, &#8451; смены режима
    </v-tooltip> -->
    <v-tooltip top>
      <template v-slot:activator="{ on }">
        <span v-on="on" class="mnemo2_elem12_2" >{{param[12].value}}&#8451;</span>
      </template>
      t, &#8451; смены режима
    </v-tooltip>
    <v-tooltip top>
      <template v-slot:activator="{ on }">
        <span v-on="on" class="mnemo2_elem13" >{{param[13].value}}&#8451;</span>
      </template>
      Т_улицы
    </v-tooltip>
    <v-tooltip top>
      <template v-slot:activator="{ on }">
        <span v-on="on" class="mnemo2_elem14_2" >{{param[14].value}}&#8451;</span>
      </template>
      Температура комфорт
    </v-tooltip>
    <v-tooltip top>
      <template v-slot:activator="{ on }">
        <span v-on="on" class="mnemo2_elem15_2" >{{param[15].value}}&#8451;</span>
      </template>
      Температура эконом
    </v-tooltip>
    <!-- <v-tooltip top>
      <template v-slot:activator="{ on }">
        <span v-on="on" class="mnemo2_elem17" >{{param[17].value}}кПа</span>
      </template>
      Уставка по давлению
    </v-tooltip> -->
    <v-tooltip top>
      <template v-slot:activator="{ on }">
        <span v-on="on" class="mnemo2_elem20" >{{param[20].value}}&#8451;</span>
      </template>
      T1 ГВС
    </v-tooltip>
    <v-tooltip top>
      <template v-slot:activator="{ on }">
        <span v-on="on" class="mnemo2_elem21" >{{param[21].value}}&#8451;</span>
      </template>
      T2 ГВС
    </v-tooltip>
    <v-tooltip top>
      <template v-slot:activator="{ on }">
        <span v-on="on" class="mnemo2_elem22" >{{param[22].value}}&#8451;</span>
      </template>
      Темп включения насоса на ГВС
    </v-tooltip>
    <v-tooltip top>
      <template v-slot:activator="{ on }">
        <span v-on="on" class="mnemo2_elem17_2" >{{param[17].value}}кПа</span>
      </template>
      Уставка по давлению
    </v-tooltip>
    <v-tooltip top>
      <template v-slot:activator="{ on }">
        <span v-on="on" class="mnemo2_elem18_2" >{{param[18].value}}</span>
      </template>
      Время комфорт
    </v-tooltip>
    <v-tooltip top>
      <template v-slot:activator="{ on }">
        <span v-on="on" class="mnemo2_elem19_2" >{{param[19].value}}</span>
      </template>
      Время эконом
    </v-tooltip>
    <v-tooltip top>
      <template v-slot:activator="{ on }">
        <span v-on="on" class="mnemo2_elemdP" >{{param[1].value-param[2].value}}кПа</span>
      </template>
      &#916;P, после элеватора
    </v-tooltip>
    <div id="mnemo2_demo11">P,кПа</div>
    <div id="mnemo2_demo12">T,&#8451;</div>
    <div id="mnemo2_demo13">G</div>
    <div id="mnemo2_demo14">
      Q
    </div>
    <div id="mnemo2_demo15">
      Qтек
    </div>
    <div id="mnemo2_demo16">
      Qчас
    </div>
    <div id="mnemo2_demo17">
      ---
    </div>
    <div id="mnemo2_demo18">
      ---
    </div>
    <div id="mnemo2_demo21">
      {{param[1].value}}
    </div>
    <div id="mnemo2_demo22">
      {{param[3].value}}
    </div>
    <div id="mnemo2_demo23">
      408
    </div>
    <div id="mnemo2_demo24">
      23,6
    </div>
    <div id="mnemo2_demo25">
      ---
    </div>
    <div id="mnemo2_demo26">
      ---
    </div>
    <div id="mnemo2_demo27">
      ---
    </div>
    <div id="mnemo2_demo28">
      ---
    </div>
    <div id="mnemo2_demo31">
      {{param[2].value}}
    </div>
    <div id="mnemo2_demo32">
      {{param[4].value}}
    </div>
    <div id="mnemo2_demo33">
      406
    </div>
    <div id="mnemo2_demo34">
      20,6
    </div>
    <div id="mnemo2_demo35">
      ---
    </div>
    <div id="mnemo2_demo36">
      ---
    </div>
    <div id="mnemo2_demo37">
      ---
    </div>
    <div id="mnemo2_demo38">
      ---
    </div>


        <!-- <v-btn
          dark
          color="teal lighten-1"
          class="mnemo2_btn1"
          @click="getParamIdInformation"
          
        >
          режим
        </v-btn> -->
        <div class="mnemo2_btn1">
          <set-param title="Уставка по давлению" :param="param[17]" />
        </div>
        <div class="mnemo2_btn2">
          <set-param title="Температура смены режима" :param="param[12]" />
        </div>
        <div class="mnemo2_btn3">
          <set-param title="Температура Комфорт" :param="param[14]" />
        </div>
        <div class="mnemo2_btn4">
          <set-param title="Температура эконом" :param="param[15]" />
        </div>
        <div class="mnemo2_btn5">
          <set-param title="Время Комфорт" :param="param[18]" />
        </div>
        <div class="mnemo2_btn6">
          <set-param title="Время эконом" :param="param[19]" />
        </div>
        <div class="mnemo2_btn7">
          <set-param title="Задание температуры ГВС" :param="param[22]" />
        </div>
  </v-container>
  <v-container id="mnemo" v-else-if="mnemoTypeis==2">
    <div id="backgranudimage-2" />
    <div class="mnemo2_wifitermo" >
      <v-img
          src="../img/wifitermo2.png"
          class="img-button"
          max-width="90"
          max-height="160"
      ></v-img>
    </div>
    <v-tooltip top>
      <template v-slot:activator="{ on }">
        <span v-on="on" class="mnemo2_btnPanel" >
          <v-btn
            color="teal lighten-1"
            @click="showSelect"
            icon
            large
          >
            <v-icon>mdi-map-search-outline</v-icon>
          </v-btn>
          </span>
      </template>
      Выбор устройства
    </v-tooltip>
  <v-tooltip top>
    <template v-slot:activator="{ on }">
      <span v-on="on" class="mnemo2_btnArchive" >
        <v-btn
          color="teal lighten-1"
          @click="downloadArchive"
          icon
          large
        >
          <v-icon>mdi-chart-areaspline</v-icon>
        </v-btn>
        </span>
    </template>
    Скачать архив значений
  </v-tooltip>
    <v-navigation-drawer class="mnemo2_selectel"
      v-model="drawer"
      app
    >
      <v-treeview 
        :items="items" 
        open-all 
        :open-on-click="true"
        activatable
        item-key="idd"
      > 
        <template slot="label" slot-scope="{ item }">
          <a @click="openDialog(item)">{{ item.name }}</a>
        </template>
      </v-treeview>
    </v-navigation-drawer>

    <v-tooltip top>
      <template v-slot:activator="{ on }">
        <span v-on="on" class="mnemo2_elem1" >{{param[1].value}}кПа</span>
      </template>
      Mod_P1_подачи
    </v-tooltip>
    <v-tooltip top>
      <template v-slot:activator="{ on }">
        <span v-on="on" class="mnemo2_elem2" >{{param[2].value}}кПа</span>
      </template>
      Mod_P2_обратки
    </v-tooltip>
    <v-tooltip top>
      <template v-slot:activator="{ on }">
        <span v-on="on" class="mnemo2_elem3" >{{param[3].value}}&#8451;</span>
      </template>
      Mod_T1подачи
    </v-tooltip>
    <v-tooltip top>
      <template v-slot:activator="{ on }">
        <span v-on="on" class="mnemo2_elem4" >{{param[4].value}}&#8451;</span>
      </template>
      Mod_T2обратки
    </v-tooltip>
    <v-tooltip top>
      <template v-slot:activator="{ on }">
        <span v-on="on" class="mnemo2_elem5" >{{param[5].value}}&#8451;</span>
      </template>
      Mod_T3смешения
    </v-tooltip>
    <v-tooltip top>
      <template v-slot:activator="{ on }">
        <span v-on="on" class="mnemo2_elem6" >{{param[6].value}}&#8451;</span>
      </template>
      T_в помещении
    </v-tooltip>
    <v-tooltip top>
      <!-- <template v-slot:activator="{ on }" v-if="param[11].value==1">
        <span v-on="on" class="mnemo2_elem7" >{{param[7].value}}&#8451;</span>
      </template> -->
      <template v-slot:activator="{ on }">
        <span v-on="on" class="mnemo2_elem7" >{{param[102].value}}&#8451;</span>
      </template>
      T_в помещении беспроводной датчик, дата последнего значения: {{param[102].lastdt}}
    </v-tooltip>
    <v-tooltip top>
      <!-- <template v-slot:activator="{ on }" v-if="param[11].value==1">
        <span v-on="on" class="mnemo2_elem_hidro" >{{param[100].value}}%</span>
      </template> -->
      <template v-slot:activator="{ on }">
        <span v-on="on" class="mnemo2_elem_hidro" >{{param[100].value}}%</span>
      </template>
      Влажность помещении беспроводной датчик
    </v-tooltip>
    <v-tooltip top>
      <!-- <template v-slot:activator="{ on }" v-if="param[11].value==1">
        <span v-on="on" class="mnemo2_elem_batt" >{{param[101].value}}В</span>
      </template> -->
      <template v-slot:activator="{ on }">
        <span v-on="on" class="mnemo2_elem_batt" >{{param[101].value}}В</span>
      </template>
      Заряд батареи беспроводной датчик
    </v-tooltip>
    <v-tooltip top>
      <template v-slot:activator="{ on }">
        <span v-on="on" class="mnemo2_elem9" >{{param[9].value}}%</span>
      </template>
      Положение клапана
    </v-tooltip>
    <!-- <v-tooltip top>
      <template v-slot:activator="{ on }">
        <span v-on="on" class="mnemo2_elem12" >{{param[12].value}}&#8451;</span>
      </template>
      t, &#8451; смены режима
    </v-tooltip> -->
    <v-tooltip top>
      <template v-slot:activator="{ on }">
        <span v-on="on" class="mnemo2_elem12_2" >{{param[12].value}}&#8451;</span>
      </template>
      t, &#8451; смены режима
    </v-tooltip>
    <v-tooltip top>
      <template v-slot:activator="{ on }">
        <span v-on="on" class="mnemo2_elem13" >{{param[13].value}}&#8451;</span>
      </template>
      Т_улицы
    </v-tooltip>
    <v-tooltip top>
      <template v-slot:activator="{ on }">
        <span v-on="on" class="mnemo2_elem14_2" >{{param[14].value}}&#8451;</span>
      </template>
      Температура комфорт
    </v-tooltip>
    <v-tooltip top>
      <template v-slot:activator="{ on }">
        <span v-on="on" class="mnemo2_elem15_2" >{{param[15].value}}&#8451;</span>
      </template>
      Температура эконом
    </v-tooltip>
    <!-- <v-tooltip top>
      <template v-slot:activator="{ on }">
        <span v-on="on" class="mnemo2_elem17" >{{param[17].value}}кПа</span>
      </template>
      Уставка по давлению
    </v-tooltip> -->
    <v-tooltip top>
      <template v-slot:activator="{ on }">
        <span v-on="on" class="mnemo2_elem20" >{{param[20].value}}&#8451;</span>
      </template>
      T1 ГВС
    </v-tooltip>
    <v-tooltip top>
      <template v-slot:activator="{ on }">
        <span v-on="on" class="mnemo2_elem21" >{{param[21].value}}&#8451;</span>
      </template>
      T2 ГВС
    </v-tooltip>
    <v-tooltip top>
      <template v-slot:activator="{ on }">
        <span v-on="on" class="mnemo2_elem22" >{{param[22].value}}&#8451;</span>
      </template>
      Темп включения насоса на ГВС
    </v-tooltip>
    <v-tooltip top>
      <template v-slot:activator="{ on }">
        <span v-on="on" class="mnemo2_elem17_2" >{{param[17].value}}кПа</span>
      </template>
      Уставка по давлению
    </v-tooltip>
    <v-tooltip top>
      <template v-slot:activator="{ on }">
        <span v-on="on" class="mnemo2_elem18_2" >{{param[18].value}}</span>
      </template>
      Время комфорт
    </v-tooltip>
    <v-tooltip top>
      <template v-slot:activator="{ on }">
        <span v-on="on" class="mnemo2_elem19_2" >{{param[19].value}}</span>
      </template>
      Время эконом
    </v-tooltip>
    <v-tooltip top>
      <template v-slot:activator="{ on }">
        <span v-on="on" class="mnemo2_elemdP" >{{param[1].value-param[2].value}}кПа</span>
      </template>
      &#916;P, после элеватора
    </v-tooltip>
    <div id="mnemo2_demo11">P,кПа</div>
    <div id="mnemo2_demo12">T,&#8451;</div>
    <div id="mnemo2_demo13">G</div>
    <div id="mnemo2_demo14">
      Q
    </div>
    <div id="mnemo2_demo15">
      Qтек
    </div>
    <div id="mnemo2_demo16">
      Qчас
    </div>
    <div id="mnemo2_demo17">
      ---
    </div>
    <div id="mnemo2_demo18">
      ---
    </div>
    <div id="mnemo2_demo21">
      {{param[1].value}}
    </div>
    <div id="mnemo2_demo22">
      {{param[3].value}}
    </div>
    <div id="mnemo2_demo23">
      408
    </div>
    <div id="mnemo2_demo24">
      23,6
    </div>
    <div id="mnemo2_demo25">
      ---
    </div>
    <div id="mnemo2_demo26">
      ---
    </div>
    <div id="mnemo2_demo27">
      ---
    </div>
    <div id="mnemo2_demo28">
      ---
    </div>
    <div id="mnemo2_demo31">
      {{param[2].value}}
    </div>
    <div id="mnemo2_demo32">
      {{param[4].value}}
    </div>
    <div id="mnemo2_demo33">
      406
    </div>
    <div id="mnemo2_demo34">
      20,6
    </div>
    <div id="mnemo2_demo35">
      ---
    </div>
    <div id="mnemo2_demo36">
      ---
    </div>
    <div id="mnemo2_demo37">
      ---
    </div>
    <div id="mnemo2_demo38">
      ---
    </div>
    <v-tooltip top>
      <template v-slot:activator="{ on }">
        <span v-on="on" class="mnemo2_demo_nasos" >{{param[23].value}}%</span>
      </template>
      Нагрузка насоса
    </v-tooltip>


        <!-- <v-btn
          dark
          color="teal lighten-1"
          class="mnemo2_btn1"
          @click="getParamIdInformation"
          
        >
          режим
        </v-btn> -->
        <div class="mnemo2_btn1">
          <set-param title="Уставка по давлению" :param="param[17]" />
        </div>
        <div class="mnemo2_btn2">
          <set-param title="Температура смены режима" :param="param[12]" />
        </div>
        <div class="mnemo2_btn3">
          <set-param title="Температура Комфорт" :param="param[14]" />
        </div>
        <div class="mnemo2_btn4">
          <set-param title="Температура эконом" :param="param[15]" />
        </div>
        <div class="mnemo2_btn5">
          <set-param title="Время Комфорт" :param="param[18]" />
        </div>
        <div class="mnemo2_btn6">
          <set-param title="Время эконом" :param="param[19]" />
        </div>
        <div class="mnemo2_btn7">
          <set-param title="Задание температуры ГВС" :param="param[22]" />
        </div>
  </v-container>
  <v-container id="mnemo" v-else-if="mnemoTypeis==3">
    <div id="backgranudimage-3" />
    <div class="mnemo3_wifitermo" >
      <v-img
          src="../img/wifitermo2.png"
          class="img-button"
          max-width="90"
          max-height="160"
      ></v-img>
    </div>
    <v-tooltip top>
      <template v-slot:activator="{ on }">
        <span v-on="on" class="mnemo3_btnPanel" >
          <v-btn
            color="teal lighten-1"
            @click="showSelect"
            icon
            large
          >
            <v-icon>mdi-map-search-outline</v-icon>
          </v-btn>
          </span>
      </template>
      Выбор устройства
    </v-tooltip>
    <v-tooltip top>
      <template v-slot:activator="{ on }">
        <span v-on="on" class="mnemo3_btnArchive" >
          <v-btn
            color="teal lighten-1"
            @click="downloadArchive"
            icon
            large
          >
            <v-icon>mdi-chart-areaspline</v-icon>
          </v-btn>
          </span>
      </template>
      Скачать архив значений
    </v-tooltip>
    <v-navigation-drawer class="mnemo3_selectel"
      v-model="drawer"
      app
    >
      <v-treeview 
        :items="items" 
        open-all 
        :open-on-click="true"
        activatable
        item-key="idd"
      > 
        <template slot="label" slot-scope="{ item }">
          <a @click="openDialog(item)">{{ item.name }}</a>
        </template>
      </v-treeview>
    </v-navigation-drawer>

    <v-tooltip top>
      <template v-slot:activator="{ on }">
        <span v-on="on" class="mnemo3_elem7" >{{param[102].value}}&#8451;</span>
      </template>
      T_в помещении беспроводной датчик, дата последнего значения: {{param[102].lastdt}}
    </v-tooltip>
    <v-tooltip top>
      <template v-slot:activator="{ on }">
        <span v-on="on" class="mnemo3_elem_hidro" >{{param[100].value}}%</span>
      </template>
      Влажность помещении беспроводной датчик, дата последнего значения: {{param[100].lastdt}}
    </v-tooltip>
    <v-tooltip top>
      <template v-slot:activator="{ on }">
        <span v-on="on" class="mnemo3_elem_batt" >{{param[101].value}}В</span>
      </template>
      Заряд батареи беспроводной датчик, дата последнего значения: {{param[101].lastdt}}
    </v-tooltip>

  </v-container>
</template>

<script>
import router from '../router'
import SetParam from '../components/setParam.vue'
import MnemoService from '../services/mnemo.service'

export default {
    components:{
      SetParam
  },
  // props:['deviceID'],
  data() {
    return {
      drawer: null,
      polling: null,
      //это id устройства для опроса
      deviceID: null,
      // param:[{value:''},{value:''},{value:''},{value:''},{value:''},{value:''},{value:''},{value:''},{value:''},{value:''},{value:''},{value:''},{value:''},{value:''},{value:''},{value:''},{value:''},{value:''},{value:''},{value:''},{value:''},{value:''},{value:''},{value:''},{value:''}],
      param: {},
      paramID: [],
      items:[],
      mnemoTypeis:2,
    };
  },
  created() {
    // this.getMnemoInformation()
    this.paramInit()

    if(this.$route.params.iddqd!=null){
      this.drawer=false
      this.deviceID = this.$route.params.iddqd
      this.getMnemoType(this.deviceID)
      // console.log('created, paramID: ', this.deviceID)
    }
    this.pollData()
    this.getTree()
  },
  mounted(){
    this.paramInit()
    this.getMnemoInformation()
  },
  beforeDestroy(){
    clearInterval(this.polling)
  },
  methods: {
    paramInit(){
      var arr1 ={};
      let dat2=new Object()

      for(var i=0; i<26;i++ ){
        dat2=new Object({"id":-100,"lastdt":"2000-01-01T00:00:00.000+00:00","paramid":0,"value":""})
        arr1[i]=dat2
      }
        dat2=new Object({"id":-100,"lastdt":"2000-01-01T00:00:00.000+00:00","paramid":0,"value":""})
        arr1[100]=dat2
        dat2=new Object({"id":-100,"lastdt":"2000-01-01T00:00:00.000+00:00","paramid":0,"value":""})
        arr1[101]=dat2
        dat2=new Object({"id":-100,"lastdt":"2000-01-01T00:00:00.000+00:00","paramid":0,"value":""})
        arr1[102]=dat2
        this.param=arr1

    },
    pollData(){
      var that = this
      this.polling = setInterval(() =>{
        // console.log('doIt')
        that.getMnemoInformation()
      },60000)
    },
    showSelect(){
      this.drawer = !this.drawer
    },
    SaveFile(filedata,filename){
      var fileURL = window.URL.createObjectURL(new Blob([filedata]));
      var fileLink = document.createElement('a');

      fileLink.href = fileURL;
      fileLink.setAttribute('download', filename);
      document.body.appendChild(fileLink);

      fileLink.click();
    },
    downloadArchive(){
      MnemoService.getArchiveParams(this.deviceID).then((response)=>{
           // Log somewhat to show that the browser actually exposes the custom HTTP header

          // console.log(response)

          const fileNameHeader = "x-suggested-filename";
          const suggestedFileName = response.headers[fileNameHeader];
          const effectiveFileName = (suggestedFileName === undefined
                ? "archive.zip"
                : suggestedFileName);
          // console.log("Received header [" + fileNameHeader + "]: " + suggestedFileName
          //       + ", effective fileName: " + effectiveFileName);
                // Let the user save the file.
          this.SaveFile(response.data, decodeURIComponent(effectiveFileName));
      }).catch((response)=>{
        console.error("Could not Download the archive from the backend.", response);
      })
    },
    openDialog(itemToOpen){
      // console.log("aaaaab: ", JSON.stringify(allo))
      const id=itemToOpen.idd
      // console.log("iddddd: ", id)
      if(id!=null){
        // console.log(this.deviceID)
        this.drawer = ! this.drawer
        if(this.deviceID != id){
          this.deviceID = id
          this.getMnemoInformation()
          this.getMnemoType(this.deviceID)
          router.push({ path: `/mnemo/${id}` }) // -> /user/123
        }
      }
    },
    async getMnemoType(deviceID){
      try {
        let mnemoType = MnemoService.getMnemoType(deviceID)
        // console.log('ждем ответа, запрос послан:',deviceID)
        mnemoType = await mnemoType;
        // console.log('дождались ответа:',mnemoType)
        this.mnemoTypeis = mnemoType.data.mnemoType
        // console.log('ответ:',this.mnemoTypeis)

      } catch (error) {
        console.log("Ошибочка вышла")
      }
    },
    async getTree() {
      // console.log("Начато дерево");
      try {
        let categories = MnemoService.getCategogies()
        let devices = MnemoService.getDevices()
        // console.log('ждем', categories.data)
        categories = await categories;
        devices = await devices;
        // console.log('дождались', categories.data)

        this.items = this.CreateTree(categories.data, devices.data);
      } catch (error) {
        this.alert = true;
        console.log("ALARM!!!!!!!!!");
        throw error;
      }
    },
    //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    CreateTree(arr, arr2) {
      // console.log("arr", arr)
      // console.log("arr2", arr2)
      var tree = [],
        tree1 = [],
        mappedArr = {},
        mappedElem;

      arr.forEach((arrElem) => {
        mappedArr[arrElem.id] = arrElem;
        mappedArr[arrElem.id]['children'] = [];
      });
      // console.log("arr-стадия1", mappedArr)

      arr2.forEach((deviceItem) => {
        mappedArr[deviceItem['category']]['children'].push(deviceItem);
      });
      // console.log("arr-стадия2", mappedArr)

      for (var id in mappedArr) {
        mappedElem = mappedArr[id];
        // If the element is not at the root level, add it to its parent array of children.
        if (mappedElem.parent_id) {
          mappedArr[mappedElem['parent_id']]['children'].push(mappedElem);
        }
        // If the element is at the root level, add it to first level elements array.
        else {
          tree.push(mappedElem);
        }
      }
      tree[0].children.map((item) => tree1.push(item));
      return tree1;
    },
    async getMnemoInformation() {
      if (this.deviceID == null) {
        // console.log("deviceID id null ");
        return;
      }

      let parametersFromDevice = MnemoService.getParametersFromDevice(this.deviceID)
      parametersFromDevice = await parametersFromDevice


      // this.param = parametersFromDevice.data
      // var arr1 = {};  

      // parametersFromDevice.data.forEach((arrElem)=>{
      //   arr1[arrElem.id-1]=arrElem
      // })
      // this.param=arr1


      parametersFromDevice.data.forEach((arrElem)=>{
        this.param[arrElem.id]=arrElem
      })
      // console.log('arr1',this.param)
      // console.log('arr1',JSON.stringify(this.param))

    },
    parseResponse1(response) {
      const respObj = response.data
      return respObj;
    },
  },
};
</script>