<template>
  <v-container>
    <h4>На данной странице Вы можете отправить заявку на обслуживание</h4>
    <h4>выберете Объект заявки и опишите проблему</h4>
    <h4></h4>
    <h1>Отправить заявку на обслуживание</h1>
    <h3>копия заявки будет отправлена вам на электронную почту</h3>
    <v-form ref="form" v-model="valid" @submit.prevent="mySendRequest">
      <v-card>
        <v-select
          v-model="selected"
          :items="devicesList"
          item-text="name"
          item-value="idd"
          :rules="[v => !!v || 'Выберете объект, для которого сотавляется заявка']"
          label="Выберете объект, для которого сотавляется заявка"
          return-object
          required
        ></v-select>
        <v-card-text>
          <v-textarea v-model="body" :rules="bodyRules" required>
            <template v-slot:label>
              <div>Опишите проблему</div>
            </template>
          </v-textarea>
        </v-card-text>
        <v-card-actions>
          <v-row align="center" no-gutters>
            <v-col class="text-center">
              <div class="my-2">
                <v-btn @click="clear">Очистить</v-btn>
              </div>
            </v-col>
            <v-col class="text-center">
              <div class="my-2">
                <v-btn
                  :disabled="!valid"
                  type="submit"
                  color="teal lighten-1"
                  class="mr-4"
                >Отправить</v-btn>
              </div>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-form>

  </v-container>
</template>

<script>
import OrderMessageRequest from "../models/OrderMessageRequest";
import MaillService from "../services/maill.service";
import MnemoService from "../services/mnemo.service";

export default {
  data() {
    return {
      orderMessageRequest: new OrderMessageRequest("", ""),
      valid: false,
      selected: null,
      body: "",
      sended: "",
      devicesList: [],
      bodyRules: [
        v => !!v || 'Опишите проблему',
      ],
    };
  },
  created() {
    this.fillForm();
  },
  methods: {
    async fillForm() {
      try {
        // let categories = MnemoService.getCategogies()
        let devices = MnemoService.getDevices();
        // categories = await categories;
        devices = await devices;
        this.devicesList = devices.data;
      } catch (error) {
        this.alert = true;
        console.log("ALARM!!!!!!!!!");
        throw error;
      }
    },
    async mySendRequest() {
      this.orderMessageRequest.subject = "Заявка на обслуживание";
      this.orderMessageRequest.message =
        "Объект заявки: " + this.selected.name + "\n\n";
      this.orderMessageRequest.message = this.orderMessageRequest.message + "Описание проблемы:\n"
      this.orderMessageRequest.message = this.orderMessageRequest.message + this.body;

      try {
        // console.log('заявка: ',JSON.stringify(this.orderMessageRequest))
        let maillService = MaillService.postSendOrder(this.orderMessageRequest);

        maillService = await maillService;
        // console.log(maillService.data)

        this.sended = maillService.data;
        this.clear();
      } catch (error) {
        console.log("Ошибочка вышла", error);
      }

      // console.log('sendrequest', this.orderMessageRequest)
    },
    validate () {
      this.$refs.form.validate()
    },
    clear() {
      this.$refs.form.reset()
    }
  }
};
</script>
