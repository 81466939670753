import axios from 'axios';
import authHeader from './auth-header';

const API_URL = '/api/admin/';

class AdminService {

  getCategogies() {
    return axios.get(API_URL + 'categories', { headers: authHeader() });
  }
  getParamdescription() {
    return axios.get(API_URL + 'paramdescription', { headers: authHeader() });
  }
  putParamdescription(data) {
    return axios.put(API_URL + 'paramdescription', data,{ headers: authHeader() });
  }
  postParamdescription(data) {
    return axios.post(API_URL + 'paramdescription', data, { headers: authHeader() });
  }
  delParamdescription(data) {
    return axios.del(API_URL + 'paramdescription', data, { headers: authHeader() });
  }

  //получается полный список устройств с облака owen
  getDeviceFromOwencloud() {
    return axios.get(API_URL + 'devicefromowen', { headers: authHeader() });
  }
  //получается полный список устройств из базы
  getDeviceFromDatabase() {
    return axios.get(API_URL + 'devicefromdatabase', { headers: authHeader() });
  }

  //для добавления устройств в базу
  setDevicesToCreate(devicesId){
    return axios.post(API_URL + 'device', devicesId, { headers: authHeader() });
  }
//для удаления устройств из базы
  setDevicesToDelete(devicesId){
    return axios.post(API_URL + 'devicetodel', devicesId, { headers: authHeader() });
  }


  getWirelesstermo() {
    return axios.get(API_URL + 'wirelesstermo', { headers: authHeader() });
  }
  putWirelesstermo(data) {
    return axios.put(API_URL + 'wirelesstermo', data,{ headers: authHeader() });
  }
  postWirelesstermo(data) {
    return axios.post(API_URL + 'wirelesstermo', data, { headers: authHeader() });
  }
  delWirelesstermo(data) {
    return axios.del(API_URL + 'wirelesstermo', data, { headers: authHeader() });
  }

  getDevicesToEdit() {
    return axios.get(API_URL + 'deviceedit', { headers: authHeader() });
  }
  putDeviceToEdit(data) {
    return axios.put(API_URL + 'deviceedit', data,{ headers: authHeader() });
  }

  //Получить список пользователей
  getUsers() {
    return axios.get(API_URL + 'user', { headers: authHeader() });
  }
  //Получить список пользователей и их устройств
  getUsersDevices() {
    return axios.get(API_URL + 'userdevice', { headers: authHeader() });
  }
  //Обновить список устройств для пользователя
  putUserDevices(data) {
    return axios.put(API_URL + 'userdevice', data, { headers: authHeader() });
  }


}

export default new AdminService();



// convertCurrency('USD', 'HRK', 20)
//   .then((message) => {
//     console.log(message);
//   }).catch((error) => {
//     console.log(error.message);
//   });


//   const getCountries = async (currencyCode) => {
//     try {
//       const response = await axios.get(`https://restcountries.eu/rest/v2/currency/${currencyCode}`);
//       return response.data.map(country => country.name);
//     } catch (error) {
//       throw new Error(`Unable to get countries that use ${currencyCode}`);
//     }
  
//   };
  
//   const convert = async (fromCurrency, toCurrency, amount) => {
//     const exchangeRate = await getExchangeRate (fromCurrency, toCurrency);
//     const countries = await getCountries(toCurrency);
//     const convertedAmount = (amount * exchangeRate).toFixed(2);
  
//     return '${amount} ${fromCurrency} is worth ${convertedAmount} ${toCurrency}. You can spend these in the following countries: ${countries}';
//   }
