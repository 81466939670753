<template>
  <v-app>
    <v-app-bar app color="teal lighten-1" dark >
      <v-toolbar-title>
        <router-link to="/home" tag="span" style="cursor: pointer" >
          <v-img src="@/img/logo.png" alt="logo" max-height="64"></v-img>
        </router-link>
      </v-toolbar-title>
      <v-spacer />
      <v-toolbar-items class="hidden-xs-only">
        <v-btn v-for="item in menuItems" :key="item.title" text :to="item.path" >
          <v-icon left>
            {{ item.icon }}
          </v-icon>
          {{ item.title }}
        </v-btn>

        <v-menu offset-y v-if="showAdminBoard" >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="teal lighten-1"
              dark
              v-bind="attrs"
              v-on="on"
            >
              Настройка
            </v-btn>
          </template>
          <v-list>
            <v-list-item
              v-for="(item, index) in menuAdminItems"
              :key="index"
              @click="AdminMenuAction(index)"
            >
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>

        <v-btn v-if="currentUser" text @click="logOut" >
          <v-icon left>
            exit_to_app
          </v-icon>
          Выход
        </v-btn>
      </v-toolbar-items>
    </v-app-bar>

    <v-main>
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
import router from './router'

export default {
  data() {
    return {
      menuAdminItems: [
        { title: 'Настройка кодов параметров', path:'/paramdescription' },
        { title: 'Заполнение таблицы устройств', path:'/createDevices' },
        { title: 'Редактирование таблицы устройств', path:'/editDevices' },
        // { title: 'Управление пользователями', path:'/registeruser' },
        { title: 'Управление устройствами пользователя', path:'/adminuserdevice' },
      ],
    }
  },
  computed: {
    menuItems() {
      if (this.currentUser) {
        return [
          { title: 'Домой', path: '/home', icon: 'home' },
          // { title: 'Мнемосхем', path: '/select', icon: 'vpn_key' },
          // { title: 'Secured page', path: '/secured', icon: 'vpn_key' },
          // { title: 'Описание параметров', path: '/paramdescription', icon: 'vpn_key' },
          { title: 'Мнемосхема', path: '/mnemo', icon: 'vpn_key' },
          { title: 'Заявка', path: '/requestsend', icon: 'mdi-email-edit-outline' },
        ];
      }
      return [
        { title: 'Домой', path: '/home', icon: 'home' },
        // { title: 'Мнемосхема', path: '/mnemo', icon: 'vpn_key' },
        // { title: 'Secured page', path: '/secured', icon: 'vpn_key' },
        { title: 'Войти', path: '/signIn', icon: 'lock_open' },
      ];
    },
    currentUser() {
      return this.$store.state.auth.user;
    },
    showAdminBoard() {
      if (this.currentUser && this.currentUser.roles) {
        return this.currentUser.roles.includes('ROLE_ADMIN');
      }

      return false;
    },
    showModeratorBoard() {
      if (this.currentUser && this.currentUser.roles) {
        return this.currentUser.roles.includes('ROLE_MODERATOR');
      }

      return false;
    }
  },
  methods: {
    AdminMenuAction(item){
      router.push({ path: this.menuAdminItems[item].path })
    },
    logOut() {
      this.$store.dispatch('auth/logout');
      this.$router.push('/');
    }
  }
};
</script>
