import axios from 'axios';
import authHeader from './auth-header';

// const API_URL = 'http://localhost:9000/api/auth/';
const API_URL = '/api/auth/';

class AuthService {
  login(user) {
    return axios
      .post(API_URL + 'signin', {
        username: user.username,
        password: user.password
      })
      .then(response => {
        if (response.data.accessToken) {
          localStorage.setItem('user', JSON.stringify(response.data));
        }

        return response.data;
      });
  }

  logout() {
    localStorage.removeItem('user');
  }

  activate(user) {
    return axios.post(API_URL + 'activate', {
      username: user.username,
      email: user.email,
      password: user.password,
      uuid: user.uuid
    });
  }

  addnewuser(user) {
    return axios.post(API_URL + 'signup', { email: user.email },{ headers: authHeader() });
  }

  emailOnUUID(uuidStr){
    // console.log('emailOnUUID начало1')
    // console.log('emailOnUUID начало2', uuidStr)
    return axios.post(API_URL + 'confirmcode', {
       uuid: uuidStr 
      });
  }


}

export default new AuthService();
